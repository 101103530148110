<template>
    <div>
        <v-overlay :value="uploadInProgress" class="text-center justify-center">
            <v-progress-circular
                indeterminate
                size="64"
                class="ml-3"
            ></v-progress-circular>
            <br/>
            <div class="mt-2">Processing location list...</div>
        </v-overlay>
        <v-row>
            <!-- Select Burst -->
            <v-col cols="3">
                <v-select
                    v-model="selectedBid"
                    item-text="name"
                    item-value="id"
                    outlined
                    label="Select Burst"
                    :items="campaignBursts"
                    color="primary"
                    @change="updateSelectedBurst"
                />
            </v-col>

            <v-spacer />

            <!-- Search -->
            <v-col cols="4">
                <v-text-field
                    autofocus
                    v-model="searchScreens"
                    outlined
                    label="Search"
                    style="width: 100%"
                    append-icon="mdi-magnify"
                    clearable
                    @click.clear="searchScreens = ''"
                ></v-text-field>
            </v-col>

            <v-spacer />

            <!-- Upload Area -->
            <v-col cols="2">
                <!-- drag & drop upload -->
                <div
                    @click="$refs.locationListUpload.click()"
                    @drop.prevent="(event) => { 
                        dragFileHover('transparent'); 
                        dragFileDrop(event); 
                    }"
                    @dragover.prevent="dragFileHover('grey');"
                    @dragleave="dragFileHover('transparent');"
                    @mouseover="dragFileHover('grey')"
                    @mouseleave="dragFileHover('transparent')"
                    class="ml-auto"
                    style="width: 150px;"
                >
                    <input
                        @click="$refs.locationListUpload.value = ''"
                        ref="locationListUpload"
                        type="file"
                        multiple
                        hidden
                        @change="clickFileUpload"
                    />
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                        <div
                            id="upload-location"
                            v-bind="attrs"
                            v-on="on"
                            :style="{
                                marginBottom: '10px',
                                padding: '10px',
                                backgroundColor: 'transparent',
                                border: 'grey 1px dashed',
                                borderRadius: '5%',
                            }"
                        >
                            <div style="width:100%; text-align:center;">
                                <v-icon large>mdi-cloud-upload</v-icon>
                            </div>
                        </div>
                        </template>
                        <span>Upload Master Location list</span>
                    </v-tooltip>
                </div>
            </v-col>
        </v-row>
        <v-row>
                    <!-- Download Location List -->
                    <v-col
                        v-if="locationListMedia"
                        cols="12"
                        align="right"
                    >
                    <div
                        @click.prevent="downloadItem({media: locationListMedia})"
                        class="mx-1"
                        style="cursor: pointer; text-align: right;"
                    >
                        <v-icon color="green" large>
                        mdi-microsoft-excel
                        </v-icon>
                        <span style="color: white; font-size: 10px;">
                        {{locationListMedia.originalFileName}}
                        </span>
                    </div>
                    </v-col>
                </v-row>

        <!-- Error Message -->
        <div v-if="errorMessage.length > 0">
            <div class="centerIt" style="color: red; font-size: 16px;">
                {{ errorMessage }}
            </div>
        </div>

        <!-- Legend/Key -->
        <div class="centerIt pa-4">
            <div class="item" v-for="item in legendItems" :key="item.color">
                <div class="color" :style="{ backgroundColor: item.color }"></div>
                <div class="label">{{ item.message }}</div>
            </div>
        </div>

        <!-- Results -->
        <v-row v-if="locationResponseItems && locationResponseItems.length > 0" class="mx-1">
            <h3>Import Results</h3>
            <v-data-table
                :headers="locationHeaders"
                :items="locationItems"
                :expanded.sync="expanded"
                item-key="id"
                show-expand
                :style="{ width: '100%'}"
            >
                <!-- Expanded view of locations with extra info -->
                <template v-slot:expanded-item="{ item }">   
                <td >
                </td>
                <td colspan="5">
                    <div v-for="(frame, index) in item.frames" v-bind:key="index"><span class="ml-4">{{ frame.extraInfo.id3 }} </span> <span class="ml-4">{{ frame.name }}</span></div>
                </td>
                </template>

                <!-- Apply the status on the left border of media owner (first column) -->
                <template v-slot:item.mediaOwner.name="{ item }" >
                    <div class="d-flex justify-between align-center ml-n4">
                        <v-tooltip right :color="matchedScreenColor(item.matched.status)">
                            <template v-slot:activator="{ attrs, on }">
                                <span
                                    v-bind="attrs"
                                    v-on="on"
                                    :style="{ borderLeft: '8px solid ' + matchedScreenColor(item.matched.status), height: '50px' }"
                                />
                            </template>
                            <span :style="{ color: 'white'}">{{ item.matched.message }}</span>
                        </v-tooltip>
                        <span class="ml-3">{{ item.mediaOwner.name }}</span>
                    </div>
                </template>
            </v-data-table>
        </v-row>

        <!-- Ignored Locations -->
        <v-row v-if="locationResponseItems && locationResponseItems.length > 0" class="mx-1">
            <h3>Ignored Locations</h3>
            <v-data-table
                :headers="ignoredLocationHeaders"
                :items="locationResponseItems.ignoredLocations"
                item-key="id"
                :style="{ width: '100%'}"
                v-model:sort-by="sortBy"
                :items-per-page="-1" 
            >
            <!-- (Show all) :items-per-page="-1" -->

                <!-- Apply the status on the left border of media owner (first column) -->
                <template v-slot:item.mediaOwner.name="{ item }" >
                    <div class="d-flex justify-between align-center ml-n4">
                        <v-tooltip right :color="matchedScreenColor(item.matched.status)">
                            <template v-slot:activator="{ attrs, on }">
                                <span
                                    v-bind="attrs"
                                    v-on="on"
                                    :style="{ borderLeft: '8px solid ' + matchedScreenColor(item.matched.status), height: '50px' }"
                                />
                            </template>
                            <span :style="{ color: 'white'}">{{ item.matched.message }}</span>
                        </v-tooltip>
                        <span class="ml-3">{{ item.mediaOwner.name }}</span>
                    </div>
                </template>
            </v-data-table>
        </v-row>
    </div>
</template>

<style>
.centerIt {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.color {
  width: 20px;
  height: 20px;
}

.label {
  font-size: 12px;
}
</style>
<script>
    import collectionSearch from '@/mixins/collection/search'
    import CampaignController from '@/services/controllers/Campaign'
    import axios from 'axios'

    export default {
        props: ['campaignBursts'],

        data: () => ({
            uploadInProgress: false,
            searchScreens: '',
            selectedBid: null,
            selectedCid: null,
            locationResponseItems: [],
            expanded: [],
            legendItems: [
                    { status: 0, message: 'Screen format exists in burst and site list', color: 'green' },
                    { status: 1, message: 'Screen format missing from Burst', color: 'orange' },
                    { status: 2, message: 'Screen format missing from Site List', color: 'red' }
            ],
            errorMessage: '',
            keyCounter: 0,
            sortBy: [{ key: 'mediaFormatName', order: 'asc' }],
            locationListMedia: null,
        }),

        computed: {
            locationHeaders: {
                // Location headings (columns) for the data table
                get() {
                    let result = []

                    result.push({ text: 'Media Owner', align: 'start', sortable: true, value: 'mediaOwner.name' })
                    result.push({ text: 'Name', align: 'start', sortable: true, value: 'name' })
                    result.push({ text: 'Category', align: 'start', sortable: true, value: 'category' })
                    result.push({ text: 'Width', align: 'start', sortable: true, value: 'width' })
                    result.push({ text: 'Height', align: 'start', sortable: true, value: 'height' })
                    result.push({ text: 'Frame/s', align: 'start', sortable: true, value: 'framesCount' })

                    return result
                }
            },

            ignoredLocationHeaders: {
                // Location headings (columns) for the data table
                get() {
                    let result = []

                    result.push({ text: 'Contractor Name', align: 'start', sortable: true, value: 'extraInfo.field5', key: 'contractorName' })
                    result.push({ text: 'Media Format Name', align: 'start', sortable: true, value: 'extraInfo.field6', key: 'mediaFormatName' })
                    result.push({ text: 'Out Of Charge', align: 'start', sortable: true, value: 'extraInfo.field7', key: 'outOfCharge' })
                    result.push({ text: 'Out Of Date', align: 'start', sortable: true, value: 'extraInfo.field8', key: 'outOfDate' })
                    result.push({ text: 'Route Frame ID', align: 'start', sortable: true, value: 'extraInfo.id3', key: 'routeFrameId' })
                    result.push({ text: 'Name', align: 'start', sortable: true, value: 'name', key: 'name' })
                    
                    
                    return result
                }
            },

            locationItems: {
                // Location items for the data table
                get() {
                    if(this.locationResponseItems && this.locationResponseItems.length > 0) {
                        // This will search the whole object passed in
                        return collectionSearch(this.locationResponseItems, 'all', this.searchScreens)
                    }
                    return []
                }
            }
        },

        async created() {
            // Attach burst id to a variable
            this.selectedCid = parseInt(this.$route.query.cid)
            this.selectedBid = parseInt(this.$route.query.bid)
            this.getSavedBurstLocationList();
        },

        methods: {
            async downloadItem({ media }) {
                let downloadUrl = media.url
                let downloadFileName = media.originalFileName
                let mimeType = media.mimeType

                let cachebuster = Math.round(new Date().getTime() / 1000)

                const response = await axios.get(downloadUrl +"?rn="+cachebuster, { responseType: "blob" })
                const blob = new Blob([response.data], { type: mimeType })
                const link = document.createElement("a")

                link.href = URL.createObjectURL(blob)
                link.download = downloadFileName
                link.click()
                
                URL.revokeObjectURL(link.href)
            },

            generateKey() {
                return this.keyCounter++;
            },

            updateSelectedBurst() {
                // Replace burst id in route
                this.$router.replace( { query: { ...this.$route.query, bid: this.selectedBid.toString() } } ).catch(err => {})
                
                // Empty the response array
                this.locationResponseItems = []

                this.getSavedBurstLocationList();
            },

            dragFileHover(bgColor) {
                // Change background color of the upload dialog box
                document.getElementById('upload-location').style.backgroundColor = bgColor
            },

            dragFileDrop (e) {
                // Function will execute after file is dragged & dropped from the explorer
                this.uploadLocationList(Object.values(e.dataTransfer.files))
            },

            clickFileUpload (e) {
                // Function will execute after file is selected in the explorer
                this.uploadLocationList(Object.values(e.target.files))
            },

            async getSavedBurstLocationList()
            {
                // Get Saved Location List Data
                const { data = false } = await CampaignController.getSavedLocationListData(this.selectedCid,this.selectedBid,0).catch((err) => {
                    // Show error toast
                    this.errorMessage = ''+ err.response.data.message
                    this.$root.$emit('snackbarError', this.errorMessage)
                    return false
                })
                
                // If data is false then return
                if (!data || data.validScreens == null) {
                    this.$root.$emit('snackbarSuccess',  'No previously saved data.')
                    return
                }

                // Show success toast
                this.$root.$emit('snackbarSuccess',  'Loaded previously processed data.')

                this.formatResponseData(data)
            },

            async uploadLocationList(files) {
                this.uploadInProgress = true;
                this.errorMessage = '';

                this.locationResponseItems = []

                const formData = new FormData()

                // Append files[0] to formData object
                formData.append('file', files[0])
                formData.append('campaignId', this.selectedCid)
                formData.append('campaignBurstId', this.selectedBid)
                formData.append('screenId', 0)

                // Upload Location List
                const { data = false } = await CampaignController.uploadLocationListByContainer(formData).catch((err) => {
                    // Show error toast
                    this.errorMessage = ''+ err.response.data.message
                    this.$root.$emit('snackbarError', this.errorMessage)
                    if(this.errorMessage.length == 0)
                    {
                        switch(error.response.status)
                        {
                            case 400:
                                this.$root.$emit('snackbarError', 'Bad request. Please contact an administrator.')
                                break;
                            case 500:
                                this.$root.$emit('snackbarError', 'Internal server error. Please contact an administrator.')
                                break;
                            case 504:
                                this.$root.$emit('snackbarError', 'Gateway Timeout. Processing took too long. Please contact an administrator.')
                                break;
                            default:
                                this.$root.$emit('snackbarError', 'An error has occurred. Please contact an administrator.')
                                break;
                        }
                    }
                    // Return false so destructuring can default
                    this.uploadInProgress = false;
                    return false
                })
                
                // If data is false then return
                if (!data) return

                // Show success toast
                this.$root.$emit('snackbarSuccess',  'Successfully uploaded master site list.')

                // Format response
                this.formatResponseData(data)
            },

            formatResponseData(data) {
                // Format response data
                this.locationResponseItems = this.formatLocationResponse(data)
                // opted to show all ignored locations, as they may be duplicated on the planned site lists - one line item might be in date,
                //let usedFrames = this.locationResponseItems.map(x => x.frames.map(y => y.extraInfo.id3)).flat()
                this.locationResponseItems.ignoredLocations = data.ignoredLocations//.filter(x => !usedFrames.includes(x.extraInfo.id3))
                this.uploadInProgress = false;

                // Get the uploaded location list media
                this.getLocationListMedia()
            },

            async getLocationListMedia() {
                // Get Location List Media
                const { data = false } = await CampaignController.getLocationListMedia(this.selectedCid, this.selectedBid, 0).catch((err) => {
                    // Show error toast
                    this.errorMessage = ''+ err.response.data.message
                    this.$root.$emit('snackbarError', this.errorMessage)
                    return false
                })
                
                // If data is false then return
                if (!data) return

                // Format response data
                this.locationListMedia = data
            },


            formatLocationResponse(data) {
                let results = []
                if (data) {
                    // Format Status according to key
                    const formatToArray = (key) => {
                        // Green -> status: 0 is if the screen is in the CampaignBurst's list of screens and in validScreens
                        // Orange -> status: 1 is the invalidScreens
                        // Red -> status: 2 = If the screen is in the CampaignBurst's list of screens and NOT in validScreens
                        switch (key) {
                            // Status 0 or 2
                            case 'validScreens':
                                this.campaignBursts.forEach(burst => {
                                    // Only add results from the burst selected in the upload
                                    if (burst.id == this.selectedBid) {
                                        burst.campaignBurstScreens.forEach(burstScreen => {
                                            // Boolean to flag if a campaign screen was found in the validScreens array
                                            let foundValidCampaignScreen = false

                                            // Loop through valid screens per campaign screen and break if result found
                                            for (let index = 0; index < data.validScreens.length; index++) {
                                                const validScreen = data.validScreens[index];
                                                // If campaign screen exists in the validScreen array,
                                                // Then push the campaign screen object with a green/0 status
                                                if (burstScreen.screen.id == validScreen.screen.id) {
                                                    // flag if a campaign screen was found
                                                    foundValidCampaignScreen = true
                                                    
                                                    // Append the match object to the validScreen matched
                                                    validScreen.matched = this.legendItems.find(item => item.status == 0)

                                                    // Push result
                                                    pushToResults(validScreen)
                                                    // Break for efficiency
                                                    break
                                                }
                                            }

                                            // If campaign screen doesn't exist in the validScreen array,
                                            // Then push the campaign screen object with a green/0 status
                                            if (!foundValidCampaignScreen) {
                                                burstScreen.matched = this.legendItems.find(item => item.status == 2)
                                                pushToResults(burstScreen) 
                                            }
                                        })
                                    }
                                })
                                break;

                            // Status Orange/1
                            case 'invalidScreens':
                                data[key].forEach((screenLocations) => {
                                    screenLocations.matched = this.legendItems.find(item => item.status == 1)
                                    pushToResults(screenLocations)
                                })
                                break;

                            // Default
                            default:
                                break;
                        }
                    }

                    // Push to results array
                    const pushToResults = (screenLocations) => {
                        const newScreen = {
                            id: ((screenLocations.screen.id) ? screenLocations.screen.id : 'empty') + ':' + results.length,
                            category: (screenLocations.screen.category) ? screenLocations.screen.category : '?',
                            width: (screenLocations.screen.width) ? screenLocations.screen.width.toString() : '?',
                            height: (screenLocations.screen.height) ? screenLocations.screen.height.toString() : '?',
                            name: (screenLocations.screen.name) ? screenLocations.screen.name : '?',
                            mediaOwner: { name: (screenLocations.screen.mediaOwner && screenLocations.screen.mediaOwner.name) ? screenLocations.screen.mediaOwner.name : '?' },
                            frames: (screenLocations.locations) ? screenLocations.locations : [],
                            framesCount: (screenLocations.locations) ? screenLocations.locations.length.toString() : 0,
                            matched: (screenLocations.matched) ? screenLocations.matched : null,
                        }

                        results.push(newScreen)
                    }

                    // Call local function for both sets of arrays from the response
                    formatToArray('validScreens')
                    formatToArray('invalidScreens')
                }

                return results
            },

            matchedScreenColor(state) {
                // 0=green, 1=orange, 2=red
                return this.legendItems.find(item => item.status == state).color
            },
        },
    }
</script>
