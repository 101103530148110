export default (collection, sortBy, orderBy) => {
    if(orderBy === 'asc') {
        return collection.sort((a, b) => {
            try {
                return a[sortBy].localeCompare(b[sortBy]) 
            }
            catch(e) {
                return
            }
        })
    } 
    else if(orderBy === 'desc') {
        return collection.sort((a, b) => {
            try {
                return b[sortBy].localeCompare(a[sortBy]) 
            }
            catch(e) {
                return
            }
        })
    }
    return
}
