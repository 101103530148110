<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        class="ma-0 mb-3 py-0"
      >
        <v-card class="my-2"
        > 
          <v-breadcrumbs :items="breadcrumbItems">
            <template v-slot:divider>
              <v-icon color="primary">
                mdi-chevron-right
              </v-icon>
            </template>
          </v-breadcrumbs>
          <v-tabs v-model="tab">
            <v-tab v-if="$store.state.Permissions.uploadMediaView">
              Upload Media
            </v-tab>        
            <v-tab v-if="$store.state.Permissions.locationBurstView">
              Locations
            </v-tab>        
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
    <v-tabs-items
      v-model="tab"
      style="background: transparent; padding-top: 10px;"
    >
      <v-tab-item 
        v-if="$store.state.Permissions.uploadMediaView"
        style="background: transparent"
      >
        <upload-media v-if="tab == 0"/>
      </v-tab-item>
      <v-tab-item
        style="background: transparent"
      >
        <upload-locations v-if="$store.state.Permissions.locationBurstView && tab == 1" :campaignBursts="campaignBursts" />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
  import UploadMedia from '@/components/artwork/UploadMedia'
  import UploadLocations from '@/components/campaigns/UploadLocations'
  
  import CampaignController from '@/services/controllers/Campaign'

  export default {
    components: {
      UploadMedia,
      UploadLocations
    },

    data: () => ({
      tab: 0,
      breadcrumbItems: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/#/dashboard/campaigns',
        },
        {
          text: 'Dashboard',
          disabled: false,
        },
        {
          text: 'Media Overview',
          disabled: true,
        },
      ],
      campaignBursts: null,
      campaignBurstScreens: [],
    }),

    watch: {
      '$route.query.bid': {
        handler: function () {
          if(this.campaignBursts != null)
          for (var i = 0; i < this.campaignBursts.length; i++) {
            if (this.campaignBursts[i].id == this.$route.query.bid) {
              this.breadcrumbItems[2].text = this.campaignBursts[i].name
            }
          }
        },
        deep: true,
        immediate: true,
      },
    },

    created () {
      this.getCampaignById()
    },

    methods: {
      getCampaignById () {
        CampaignController.getCampaign(this.$route.query.cid).then((res) => {
          this.campaign = res.data
          this.campaignBursts = res.data.campaignBursts

          this.breadcrumbItems[1].text = res.data.name
          this.breadcrumbItems[1].href = '/#/dashboard/campaigns/campaign?cid=' + this.$route.query.cid
          
          if (this.$route.query.bid) {
            for (var i = 0; i < res.data.campaignBursts.length; i++) {
              if (res.data.campaignBursts[i].id == this.$route.query.bid) {
                this.breadcrumbItems[2].text = res.data.campaignBursts[i].name
              }
            }
          }
        })
      },
    },
 
  }
</script>
