var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{staticClass:"text-center justify-center",attrs:{"value":_vm.uploadInProgress}},[_c('v-progress-circular',{staticClass:"ml-3",attrs:{"indeterminate":"","size":"64"}}),_c('br'),_c('div',{staticClass:"mt-2"},[_vm._v("Processing location list...")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"item-text":"name","item-value":"id","outlined":"","label":"Select Burst","items":_vm.campaignBursts,"color":"primary"},on:{"change":_vm.updateSelectedBurst},model:{value:(_vm.selectedBid),callback:function ($$v) {_vm.selectedBid=$$v},expression:"selectedBid"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticStyle:{"width":"100%"},attrs:{"autofocus":"","outlined":"","label":"Search","append-icon":"mdi-magnify","clearable":""},on:{"click":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"clear",undefined,$event.key,undefined)){ return null; }_vm.searchScreens = ''}},model:{value:(_vm.searchScreens),callback:function ($$v) {_vm.searchScreens=$$v},expression:"searchScreens"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"ml-auto",staticStyle:{"width":"150px"},on:{"click":function($event){return _vm.$refs.locationListUpload.click()},"drop":function($event){$event.preventDefault();return (function (event) { 
                    _vm.dragFileHover('transparent'); 
                    _vm.dragFileDrop(event); 
                }).apply(null, arguments)},"dragover":function($event){$event.preventDefault();return _vm.dragFileHover('grey');},"dragleave":function($event){return _vm.dragFileHover('transparent');},"mouseover":function($event){return _vm.dragFileHover('grey')},"mouseleave":function($event){return _vm.dragFileHover('transparent')}}},[_c('input',{ref:"locationListUpload",attrs:{"type":"file","multiple":"","hidden":""},on:{"click":function($event){_vm.$refs.locationListUpload.value = ''},"change":_vm.clickFileUpload}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({style:({
                            marginBottom: '10px',
                            padding: '10px',
                            backgroundColor: 'transparent',
                            border: 'grey 1px dashed',
                            borderRadius: '5%',
                        }),attrs:{"id":"upload-location"}},'div',attrs,false),on),[_c('div',{staticStyle:{"width":"100%","text-align":"center"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-cloud-upload")])],1)])]}}])},[_c('span',[_vm._v("Upload Master Location list")])])],1)])],1),_c('v-row',[(_vm.locationListMedia)?_c('v-col',{attrs:{"cols":"12","align":"right"}},[_c('div',{staticClass:"mx-1",staticStyle:{"cursor":"pointer","text-align":"right"},on:{"click":function($event){$event.preventDefault();return _vm.downloadItem({media: _vm.locationListMedia})}}},[_c('v-icon',{attrs:{"color":"green","large":""}},[_vm._v(" mdi-microsoft-excel ")]),_c('span',{staticStyle:{"color":"white","font-size":"10px"}},[_vm._v(" "+_vm._s(_vm.locationListMedia.originalFileName)+" ")])],1)]):_vm._e()],1),(_vm.errorMessage.length > 0)?_c('div',[_c('div',{staticClass:"centerIt",staticStyle:{"color":"red","font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])]):_vm._e(),_c('div',{staticClass:"centerIt pa-4"},_vm._l((_vm.legendItems),function(item){return _c('div',{key:item.color,staticClass:"item"},[_c('div',{staticClass:"color",style:({ backgroundColor: item.color })}),_c('div',{staticClass:"label"},[_vm._v(_vm._s(item.message))])])}),0),(_vm.locationResponseItems && _vm.locationResponseItems.length > 0)?_c('v-row',{staticClass:"mx-1"},[_c('h3',[_vm._v("Import Results")]),_c('v-data-table',{style:({ width: '100%'}),attrs:{"headers":_vm.locationHeaders,"items":_vm.locationItems,"expanded":_vm.expanded,"item-key":"id","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
                        var item = ref.item;
return [_c('td'),_c('td',{attrs:{"colspan":"5"}},_vm._l((item.frames),function(frame,index){return _c('div',{key:index},[_c('span',{staticClass:"ml-4"},[_vm._v(_vm._s(frame.extraInfo.id3)+" ")]),_vm._v(" "),_c('span',{staticClass:"ml-4"},[_vm._v(_vm._s(frame.name))])])}),0)]}},{key:"item.mediaOwner.name",fn:function(ref){
                        var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-between align-center ml-n4"},[_c('v-tooltip',{attrs:{"right":"","color":_vm.matchedScreenColor(item.matched.status)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var attrs = ref.attrs;
                        var on = ref.on;
return [_c('span',_vm._g(_vm._b({style:({ borderLeft: '8px solid ' + _vm.matchedScreenColor(item.matched.status), height: '50px' })},'span',attrs,false),on))]}}],null,true)},[_c('span',{style:({ color: 'white'})},[_vm._v(_vm._s(item.matched.message))])]),_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(item.mediaOwner.name))])],1)]}}],null,false,1070011144)})],1):_vm._e(),(_vm.locationResponseItems && _vm.locationResponseItems.length > 0)?_c('v-row',{staticClass:"mx-1"},[_c('h3',[_vm._v("Ignored Locations")]),_c('v-data-table',{style:({ width: '100%'}),attrs:{"headers":_vm.ignoredLocationHeaders,"items":_vm.locationResponseItems.ignoredLocations,"item-key":"id","items-per-page":-1},scopedSlots:_vm._u([{key:"item.mediaOwner.name",fn:function(ref){
                        var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-between align-center ml-n4"},[_c('v-tooltip',{attrs:{"right":"","color":_vm.matchedScreenColor(item.matched.status)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var attrs = ref.attrs;
                        var on = ref.on;
return [_c('span',_vm._g(_vm._b({style:({ borderLeft: '8px solid ' + _vm.matchedScreenColor(item.matched.status), height: '50px' })},'span',attrs,false),on))]}}],null,true)},[_c('span',{style:({ color: 'white'})},[_vm._v(_vm._s(item.matched.message))])]),_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(item.mediaOwner.name))])],1)]}}],null,false,2064730530),model:{value:(_vm.sortBy),callback:function ($$v) {_vm.sortBy=$$v},expression:"sortBy"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }